/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "ap-southeast-1",
  "aws_cognito_identity_pool_id": "ap-southeast-1:b49dce2a-ce40-4e30-af67-acb1c2207cfa",
  "aws_cognito_region": "ap-southeast-1",
  "aws_user_pools_id": "ap-southeast-1_qA1kAhEnm",
  "aws_user_pools_web_client_id": "7i8frimhmvqnsco2q5ve25bhnl",
  "oauth": {},
  "aws_appsync_graphqlEndpoint": "https://aantbitrjjd4rkv6rzad7ipcfe.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  "aws_appsync_region": "ap-southeast-1",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-jjedqfxgi5bglatoo2wr7xo23a",
  "aws_user_files_s3_bucket": "ecom-webassets-stg",
  "aws_user_files_s3_bucket_region": "ap-southeast-1"
};

export default awsmobile;

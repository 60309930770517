import React, { useContext, createContext } from "react";

const MerchantContext = createContext({
  color: "#42a5f5",
  font: "Helvetica",
  currency: "",
  language: "MYR",
  merchantName: "",
  phoneNo: "",
  emailAddress: "",
  address: "",
  merchantId: "",
  domain:"",
});

export default MerchantContext;

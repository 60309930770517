export const appRoutes = Object.freeze({
  HOME: "/",
  LOGIN_PAGE: "/login",
  ORDER: "/order",
  ADDRESS_BOOK: "/address-book",
  EDIT_ADDRESS_BOOK: "/edit-address-book",
  MY_PROFILE: "/my-profile",
  WISHLIST: "/wishlist",
  COLLECTIONS_MENU: "/collections-menu",
  BOOKMARK: "/bookmark",

  signInRedirect: "/account/redirect",
  signUp: "/account/sign-up",
  forgotPassword: "/forgot-your-password",
  resetPassword: "/reset-password",
  contactUs: "/contact-us",
  products: "/products",
  collection: "/collection/:collectionName",
  orderDetail: "/order/:id",
  orderTracking: "/order/tracking/:id",
  orderListing: "/order",
  policies: "/policies/:policy",
  terms: "/terms",
  viewCart: "/view-cart",
  checkOut: "/checkout",
  addressBook: "/address-book",
  editAddressBook: "/edit-address-book",
  productDetail: "/product/:id",
  productBundle: "/product-bundle/:id",
  paymentSuccess: "/payment-success",
  paymentFailed: "/payment-failed",
  bannerDetail: "/banner/:bannerId",
  search: "/search",
});

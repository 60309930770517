import { styled, Box, Alert, AlertColor, Slide } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { createPortal } from "react-dom";
import { Stack } from "@mui/system";
import React from "react";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

let id = 0;

const ToastContext = createContext<{
  addToast: (props: { content: string; severity: AlertColor }) => void;
  removeToast: (id: number) => void;
}>({
  removeToast: () => {},
  addToast: () => {},
});

const Toast = ({
  children,
  severity,
  id,
}: {
  children: ReactElement;
  severity: AlertColor;
  id: number;
}) => {
  const { removeToast } = useNotification();

  useEffect(() => {
    const timer = setTimeout(async () => {
      removeToast(id);
    }, 3000);

    return () => clearTimeout(timer);
  }, [id, removeToast]);

  return (
    <React.Fragment>
      <Alert
        onClose={() => removeToast(id)}
        variant="filled"
        sx={{
          borderRadius: "6px",
          width: "100%",
          my: 0.6,
          backgroundColor: {
            success: "#16B364",
            warning: "orange",
            error: "#F04438",
            info: "#5C8AFF",
          }[severity],
        }}
      >
        {children}
      </Alert>
    </React.Fragment>
  );
};

const ToastWrapper = styled(Box)`
  transform: translateX(-50%);
  position: fixed;
  z-index: 10000;
  left: 50%;
  top: 12px;
`;

const ToastContainer = ({
  toasts,
}: {
  toasts: {
    content: string;
    id: number;
    severity: AlertColor;
  }[];
}) => {
  if (typeof window === "object") {

    return createPortal(
      <ToastWrapper>
      <Stack direction={"column"} spacing={1}>
        <TransitionGroup>
          {toasts?.map((item) => (
            <Slide key={item.id}>
              <Box sx={{ width: "100%" }}>
                <Toast key={item.id} id={item?.id} severity={item?.severity}>
                  <Box>{item.content}</Box>
                </Toast>
              </Box>
            </Slide>
          ))}
        </TransitionGroup>
      </Stack>
    </ToastWrapper>,
    document.getElementById("main-content") || document.body
    );
  } else {
    return null;
  
  }
};

export const ToastProvider = ({ children }: { children: ReactElement }) => {
  const [toasts, setToasts] = useState<any[]>([]);

  const addToast: (props: { content: string; severity: AlertColor }) => void =
    useCallback(
      ({ content, severity }) =>
        setToasts((toasts) => [...toasts, { id: id++, content, severity }]),
      [setToasts]
    );

  const removeToast: (c: number) => void = useCallback(
    (id: number) => setToasts((toasts) => toasts?.filter((t) => t.id !== id)),
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export default function useNotification() {
  return useContext(ToastContext);
}

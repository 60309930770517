import Image from "next/image";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
// pages/404.js
import SEO from "@/components/seo/SEO";
import { ContainedButton } from "@/components/buttons/Buttons";
import { useContext } from "react";
import MerchantContext from "@/contexts/MerchantContext";
// image
import notFound from "@/images/404.png";
import { StoreTypes } from "@/enums/enums";

export default function Custom404({ showButton = true }) {
  const { storeType } = useContext(MerchantContext);
  let router = useRouter();
  const { t } = useTranslation("common");
  return (
    <>
      <SEO
        title="404 - Page Not Found"
        keywords=""
        description="404 - Page Not Found"
      />
      <div className="flex flex-col items-center p-3 pb-10">
        <div>
          <h1 className="text-blue text-[45px]">404</h1>
          <h1 className="text-blue -mt-8 ">{t("Oops! Page not found")}</h1>
        </div>
        <div className="flex justify-center">
          <Image src={notFound} width={370} height={400} />
        </div>
        <div className="text-blue ml-3">
          <p>
            {t("We're sorry, the page you requested could not be found")}{" "}
            {showButton ? t("Please go back to the homepage") : ""}
          </p>
        </div>
        {showButton && StoreTypes.WHATSAPP_CRM_STORETYPE !== storeType && (
          <ContainedButton
            className="w-11/12 mt-8 960-up:w-32 bg-primary"
            border="rounded-full"
            onClick={() => router.push("/")}
            selection={true}
          >
            <div className="text-white">{t("HOME")}</div>
          </ContainedButton>
        )}
      </div>
    </>
  );
}

import React from "react";
import useTranslation from "next-translate/useTranslation";
// icons
import { LoadingIcon, FavIcon } from "@/components/icons/Icons";
import { PlusSmIcon, MinusSmIcon, XIcon } from "@heroicons/react/outline";
import { WhatsAppIcon } from "@/components/icons/Icons";

export const BadgeButton = (props) => {
  const { icon, number, onClick, ...rest } = props;
  const { t } = useTranslation("common");
  return (
    // <button class="relative bg-blue-800 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">

    // Button
    //   <span class="absolute bg-red-600 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
    //     99+
    //   </span>
    // </button>

    <span
      className={`relative bg-primary duration-300 py-1 px-1 inline-flex ${
        props?.className ?? ""
      }`}
      {...rest}
      onClick={() => (onClick ? onClick() : function () {})}
    >
      {icon}
      {number !== 0 && (
        <span
          className="absolute text-white text-[0.6rem] bg-red-600 px-2 py-1 font-bold rounded-full -top-2 -right-2 cursor-pointer z-10"
          onClick={() => (onClick ? onClick() : function () {})}
        >
          {number > 9 ? "9+" : number}
        </span>
      )}
    </span>
  );
};

/*
  current file has
  1. ContainedButton: 
      button with loading state and primary bg, default font size is 15px
      set outlined true can get outlined style
*/

export const ContainedButton = (props) => {
  const {
    loading = false,
    type = "button",
    className = "",
    fontSize = "text-[14px]", // set button content font size
    outlined = false,
    border = "rounded-full",
    padding = true,
    color = outlined ? "text-primary" : "text-white",
    selection = false,
    ...rest
  } = props;
  const { t } = useTranslation("common");
  return (
    <button
      type={type}
      className={[
        className,
        "disabled:bg-gray-300 disabled:text-grey-350 disabled:cursor-auto disabled:shadow-none",
        "relative overflow-hidden inline-flex items-center justify-center outline-none text-center transition-all ease-in-out duration-250",
        border,
        "py-2.5 px-5",
        outlined
          ? selection
            ? "bg-gray-200 border-none hover:bg-gray-300"
            : "bg-white border hover:bg-gray-100"
          : "bg-primary shadow-button hover:shadow-button-hover",
        fontSize,
        color,
      ].join(" ")}
      {...rest}
    >
      <span className="relative pointer-events-none">
        <>
          {/* {loading ? <LoadingIcon /> : <>{props.children}</>} */}

          {loading ? <LoadingIcon margin="mr-3" /> : null}
          {loading ? t("Processing") : props.children}
        </>
      </span>
    </button>
  );
};

export const QuantityButtons = (props) => {
  const {
    handleClickAdd,
    handleClickRemove,
    quantity = 0,
    buttonDecreaseDisabled,
    buttonIncreaseDisabled,
    ...rest
  } = props;

  return (
    <div {...rest}>
      <div role="group" className="inline-flex border rounded-full bg-white">
        <button
          className={[
            "p-2 rounded-full hover:bg-opacity-5 hover:bg-black",
            buttonDecreaseDisabled ? "text-gray-300" : "",
          ].join(" ")}
          title="decrease"
          disabled={buttonDecreaseDisabled}
          onClick={handleClickRemove}
          type="button"
        >
          <MinusSmIcon className="w-6 h-6" />
        </button>
        <button
          className="text-black text-[18px] w-[34px] pointer-events-none"
          disabled
        >
          {quantity}
        </button>
        <button
          className={[
            "p-2 rounded-full hover:bg-opacity-5 hover:bg-black",
            buttonIncreaseDisabled ? "text-gray-300" : "",
          ].join(" ")}
          title="increase"
          onClick={handleClickAdd}
          disabled={buttonIncreaseDisabled}
          type="button"
        >
          <PlusSmIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export const VariantButton = (props) => {
  const {
    type = "button",
    className = "",
    fontSize = "text-[14px]", // set button content font size
    ...rest
  } = props;
  return (
    <button
      type={type}
      className={[
        className,
        "disabled:bg-gray-300 disabled:text-grey-350 disabled:cursor-auto disabled:shadow-none",
        "relative overflow-hidden inline-flex items-center justify-center outline-none font-md text-center transition-all ease-in-out duration-250",
        "border hover:bg-primary/50 py-[5px] px-[15px] rounded",
        fontSize,
      ].join(" ")}
      {...rest}
    >
      <span className="relative pointer-events-none">{props.children}</span>
    </button>
  );
};

export const FavIconButton = (props) => {
  const { status, ...rest } = props;
  return (
    <button
      title="favourite"
      className="bg-white border border-gray-400 rounded-full p-2.5 m-[5px] inline-flex "
      {...rest}
    >
      <FavIcon status={status} />
    </button>
  );
};

export const FloatingButton = (props) => {
  const { whatsappNo, icons } = props;
  // open url in new tab
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  // trigger open whatsapp url
  const handleOnClick = () => {
    openInNewTab(
      `https://api.whatsapp.com/send/?phone=${whatsappNo?.replace("+", "")}&text&app_absent=0`
    );
  };
  if ([null, ""].includes(whatsappNo)) return <></>;

  return (
    <div
      className="bg-[#25d366] rounded-full p-3 fixed right-8 bottom-[50px] z-20 cursor-pointer shadow-button"
      onClick={handleOnClick}
    >
      <WhatsAppIcon />
    </div>
  );
};

export const RoundCloseButton = (props) => {
  const { handleOnClick } = props;

  return (
    <button
      className="bg-white rounded-full fixed bottom-[1px] p-3 z-[2000] cursor-pointer text-primary mb-4"
      onClick={handleOnClick}
      type="button"
    >
      <XIcon className="w-6 h-6" />
    </button>
  );
};
